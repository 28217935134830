import React from "react";
import styled from "styled-components";
import { fontR12r } from "../../styles/fonts";
import colors from "../../styles/colors";

const Button = styled.span`
  ${fontR12r};
  padding: 2px 12px;
  margin: 0 8px 8px 0;
  text-align: left;
  border-radius: 100px;
  border: none;
`;

const TagButton = ({ type, text, style = null, ...props }) => {
  let tagStyle = {};
  if (type === "primary") {
    tagStyle = {
      color: colors.white,
      fontSize: "10px",
      backgroundColor: colors.darkBlue,
      textTransform: "uppercase",
      letterSpacing: "2px",
    };
  }
  if (type === "secondary") {
    tagStyle = {
      color: colors.cyanBlue,
      backgroundColor: colors.whiteSmoke,
    };
  }
  return (
    <Button {...props} style={{ ...tagStyle, ...style }}>
      {text}
    </Button>
  );
};

export default TagButton;
