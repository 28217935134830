import React from "react";
import Facebook from "../../assets/icons/Facebook.svg";
import Twitter from "../../assets/icons/Twitter.svg";
import LinkedIn from "../../assets/icons/LinkedIn.svg";
import styled from "styled-components";
import { Location } from "@reach/router";
import { fontR12r } from "../../styles/fonts";
import colors from "../../styles/colors";
import { FormattedMessage } from "react-intl";

const Icon = styled.div`
  display: inline-block;
  img {
    height: 24px;
    width: auto;
  }
`;

const Text = styled.div`
  font: ${fontR12r};
  color: ${colors.blueGrey};
`;

const Container = styled.div`
  width: 192px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SocialMediaSharePost = (props) => (
  <Location>
    {({ location }) => (
      <Container>
        <Text>
          <FormattedMessage id="share_this_post" />
        </Text>
        <Icon>
          <a
            rel="noopener noreferrer"
            target={"_blank"}
            href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
          >
            <img loading={"lazy"} alt={"Facebook Icon"} src={Facebook} />
          </a>
        </Icon>
        <Icon>
          <a
            rel="noopener noreferrer"
            target={"_blank"}
            href={`https://twitter.com/home?status=This%20is%20an%20interesting%20read%0A${location.href}`}
          >
            <img loading={"lazy"} alt={"Twitter Icon"} src={Twitter} />
          </a>
        </Icon>
        <Icon>
          <a
            rel="noopener noreferrer"
            target={"_blank"}
            href={`https://www.linkedin.com/shareArticle?url=${location.href}`}
          >
            <img loading={"lazy"} alt={"LinkedIn Icon"} src={LinkedIn} />
          </a>
        </Icon>
      </Container>
    )}
  </Location>
);

export default SocialMediaSharePost;
