import React from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import Grid from "../../styles/Grid";
import Slices from "../shared/Slices";
import RichText from "../shared/RichText";

const Container = styled.div`
  padding: 60px 0;
  max-width: 840px;
  grid-column: 2 / span 4;

  @media (min-width: ${breakpoints.lg}) {
    padding: 80px 0;
    grid-column: 3 / span 10;
  }

  @media (min-width: ${breakpoints.xl}) {
    grid-column: 4 / span 8;
  }
`;

// Handling for old blog post until we migrate all content to new structure
const ContainerDepreciated = styled(Grid)`
  padding: 60px 0;

  ${RichText} {
    max-width: 840px;
    grid-column: 2 / span 4;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 80px 0;

    ${RichText} {
      grid-column: 3 / span 10;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    ${RichText} {
      grid-column: 4 / span 8;
    }
  }
`;

const BodySection = ({ content = null, children = null, ...props }) => {
  let body;
  if (content) {
    body = (
      <Grid>
        <Container>
          <Slices slices={content} />
        </Container>
      </Grid>
    );
  }

  // Handling for old blog post until we migrate all content to new structure
  if (children) {
    body = (
      <ContainerDepreciated {...props}>
        <RichText>{children}</RichText>
      </ContainerDepreciated>
    );
  }

  return body;
};

export default BodySection;
