import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/shared/seo";
import HeaderSection from "../components/blogPost/HeaderSection";
import BodySection from "../components/blogPost/BodySection";
import { RichText } from "prismic-reactjs";
import Slices from "../components/shared/Slices";
import styled from "styled-components";
import Grid from "../styles/Grid";
import breakpoints from "../styles/breakpoints";
import SocialMediaSharePost from "../components/shared/SocialMediaSharePost";
import routes from "../routes";
import BlogSubscriptionBanner from "../components/shared/BlogSubscriptionBanner";
import TagButton from "../components/shared/TagButton";
import Button from "../components/shared/CTAButton";
import { fontR14m } from "../styles/fonts";
import colors from "../styles/colors";
import { FormattedMessage } from "react-intl";

const TagTitle = styled.span`
  ${fontR14m};
  margin-right: 12px;
  text-transform: uppercase;
`;

const Tags = styled.div``;

const TagsSection = styled.div`
  margin-top: 20px;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${colors.greyBlue};
  border-bottom: 1px solid ${colors.greyBlue};
`;

const CustomButton = styled(Button)`
  padding: 16px 40px;
  margin: 32px 0;
  font-size: 16px;
  font-weight: normal;
  text-transform: none;
  color: ${colors.green};
  background-color: transparent;
  border: 1px solid ${colors.green};
  border-radius: 3px;
  width: 100%;
  :hover {
    color: ${colors.green};
    background-color: transparent;
  }
  @media (min-width: ${breakpoints.lg}) {
    width: auto;
  }
`;
const CTASection = styled.div``;

const FooterSection = styled(Grid)`
  ${TagsSection},
  ${CTASection} {
    max-width: 840px;
    grid-column: 2 / span 4;
  }

  @media (min-width: ${breakpoints.lg}) {
    ${TagsSection},
    ${CTASection} {
      grid-column: 3 / span 10;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    ${TagsSection},
    ${CTASection} {
      grid-column: 4 / span 8;
    }
  }
`;

class BlogPostTemplate extends React.Component {
  // componentDidMount() {
  //   window.analytics.page("Blogpost");
  // }

  render() {
    const {
      location,
      pageContext: { locale },
      data,
    } = this.props;
    const pageData = data.prismicBlogPost.data;
    const rawData = data.prismicBlogPost.dataRaw;
    const tags = data.prismicBlogPost.tags;

    const hasOldBlogContent = rawData.body.length > 0;
    const oldBlogContentIsEmpty =
      rawData.body.length === 1 && rawData.body[0].text === "";

    const hasBlogContent = rawData.body1.length > 0;
    const blogContentIsEmpty =
      rawData.body1.length === 1 && rawData.body1[0].text === "";

    const hasFooterCTA = rawData.body2.length > 0;

    return (
      <Layout location={location} locale={locale}>
        <Seo
          title={`${pageData.title} | Workmate`}
          description={pageData.author}
          image={pageData.banner_image.url}
        />
        <HeaderSection
          title={pageData.title}
          bannerImage={pageData.banner_image}
          author={pageData.author}
          date={pageData.date}
          avatar={pageData.avatar}
          tags={tags}
        />

        {/* TODO: REMOVE once all blog post have been moved to new structure on prismic */}
        {hasOldBlogContent && !oldBlogContentIsEmpty && (
          <BodySection children={RichText.render(rawData.body)} />
        )}

        {hasBlogContent && !blogContentIsEmpty && (
          <BodySection content={rawData.body1} />
        )}

        <FooterSection>
          <TagsSection>
            <Tags>
              <TagTitle>
                <FormattedMessage id="tags" />:
              </TagTitle>
              {tags.slice(0, 3).map((tag, index) => (
                <TagButton key={index} type="secondary" text={tag} />
              ))}
            </Tags>
            <SocialMediaSharePost />
          </TagsSection>
          <CTASection>
            <CustomButton to={routes.blog}>
              <FormattedMessage id={"back_to_all_posts"} />
            </CustomButton>
          </CTASection>
        </FooterSection>
        {hasFooterCTA ? (
          <Grid>
            <Slices slices={rawData.body2} />
          </Grid>
        ) : (
          <BlogSubscriptionBanner
            title={data.prismicBlogListPage.data.cta_banner_title}
            description={data.prismicBlogListPage.data.cta_banner_description}
            hubspotFormId={data.prismicBlogListPage.data.hubspot_form_id}
          />
        )}
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const query = graphql`
  query BlogPostQuery($slug: String, $localeKey: String) {
    prismicBlogPost(uid: { eq: $slug }, lang: { eq: $localeKey }) {
      tags
      data {
        title
        author
        date
        avatar {
          alt
          gatsbyImageData
        }
        banner_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      dataRaw
    }
    prismicBlogListPage(lang: { eq: $localeKey }) {
      data {
        cta_banner_title
        cta_banner_description
        hubspot_form_id
      }
    }
  }
`;
