import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Grid from "../../styles/Grid";
import { fontH1, fontR14r, fontR14m } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import SocialMediaSharePost from "../shared/SocialMediaSharePost";
import moment from "moment";
import TagButton from "../shared/TagButton";
import colors from "../../styles/colors";

const Title = styled.h1`
  ${fontH1};
  text-align: center;
  padding: 16px 0;
`;

const Tag = styled.div`
  text-align: center;
`;

const BannerImage = styled.div`
  margin-bottom: 32px;
  img {
    width: 100%;
  }
`;

const Author = styled.div`
  color: ${colors.blueGrey};
  ${fontR14m};
`;

const Date = styled.div`
  color: ${colors.blueGrey};
  ${fontR14r};
`;

const AvatarImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const Avatar = styled.div`
  margin-right: 12px;
  width: 50px;
  height: 50px;
`;

const AuthorDate = styled.div`
  ${fontR14r};
  color: ${colors.blueGrey};
  display: flex;
  flex-direction: column;
`;

const AuthorDateAvatar = styled.div`
  display: flex;
`;

const AuthorContainer = styled.div`
  margin-top: 20px;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e1e5ee;
  border-bottom: 1px solid #e1e5ee;
`;

const Container = styled(Grid)`
  padding-top: 40px;

  grid-column: 2 span 4;

  ${Title},
  ${BannerImage},
  ${AuthorContainer} {
    max-width: 840px;
    grid-column: 2 / span 4;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding-top: 90px;

    ${Title},
    ${BannerImage},
    ${AuthorContainer} {
      grid-column: 3 / span 10;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    padding-top: 90px;

    ${Title},
    ${BannerImage},
    ${AuthorContainer} {
      grid-column: 4 / span 8;
    }
  }
`;

const HeaderSection = ({
  title,
  bannerImage,
  author,
  date,
  avatar,
  tags,
  ...props
}) => {
  const bannerImageData = getImage(bannerImage);
  const avatarImageData = getImage(avatar);
  return (
    <Container {...props}>
      {bannerImageData && (
        <BannerImage>
          <GatsbyImage image={bannerImageData} alt={bannerImage.alt} />
        </BannerImage>
      )}
      <Tag>
        {tags.slice(0, 3).map((tag, index) => (
          <TagButton key={index} type="primary" text={tag} />
        ))}
      </Tag>
      <Title>{title}</Title>
      <AuthorContainer>
        <AuthorDateAvatar>
          {avatarImageData && (
            <Avatar>
              <AvatarImage image={avatarImageData} alt={avatar.alt} />
            </Avatar>
          )}
          <AuthorDate>
            <Author>{author}</Author>
            <Date>{date && moment(date).format("D MMM YYYY")}</Date>
          </AuthorDate>
        </AuthorDateAvatar>
        <SocialMediaSharePost />
      </AuthorContainer>
    </Container>
  );
};

export default HeaderSection;
