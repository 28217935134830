import React from "react";
import styled from "styled-components";
import Grid from "../../styles/Grid";
import breakpoints from "../../styles/breakpoints";
import { fontFG28m, fontR14r } from "../../styles/fonts";
import colors from "../../styles/colors";
import { HUBSPOT_PORTAL_ID } from "../../config";

const Title = styled.div`
  ${fontFG28m};
  color: ${colors.white};
  padding: 20px 0;
`;

const Description = styled.div`
  ${fontR14r};
  color: ${colors.white};
  line-height: 22px;
`;

const SubscriptionField = styled.div`
  padding: 40px 0 30px 0;
  text-align: center;
  .hs-input {
    width: 100%;
    height: 46px;
    padding: 13px 20px;
    background-color: transparent;
    color: ${colors.white};
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    resize: none;
    outline: none;
    ::placeholder {
      color: ${colors.white};
    }
  }
  .hs-form-required {
    display: none;
  }
  .hs-error-msgs {
    margin: 2px 0;
    text-align: left;
    color: ${colors.red};
  }
  .hs-button {
    margin-top: 6px;
    width: 100%;
    height: 46px;
    padding: 10px 20px;
    background-color: ${colors.green};
    color: ${colors.white};
    font-weight: 500;
    border-radius: 4px;
    letter-spacing: 2px;
    border: none;
  }
  .submitted-message {
    color: ${colors.white};
  }
  @media (min-width: ${breakpoints.lg}) {
    position: relative;
    .hs-button {
      margin: 0;
      width: 158px;
      position: absolute;
      top: 40px;
      right: 0px;
    }
  }
`;

const Contents = styled.div``;

const Background = styled(Grid)`
  border-radius: 6px;
  background-color: ${colors.darkBlue};
  padding: 40px 0;
  text-align: center;
  ${Contents} {
    grid-column: 2 / span 4;
  }
  @media (min-width: ${breakpoints.lg}) {
    ${Contents} {
      grid-column: 5 / span 6;
    }
  }
`;

const Container = styled(Grid)`
  margin-top: 40px;
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 80px;
  }
`;

class BlogSubscriptionBanner extends React.Component {
  componentDidMount() {
    // Hubspot email subscription form
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: HUBSPOT_PORTAL_ID,
        formId: this.props.hubspotFormId,
        target: "#blog_subscription_hubspot_form"
      });
    }
  }
  render() {
    const { title, description } = this.props;
    return (
      <Container {...this.props}>
        <Background>
          <Contents>
            <Title>{title}</Title>
            <Description>{description}</Description>
            <SubscriptionField id="blog_subscription_hubspot_form" />
          </Contents>
        </Background>
      </Container>
    );
  }
}

export default BlogSubscriptionBanner;
